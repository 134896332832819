<template>

  <div class="row" ref="branchForm">

    <div class="col-xs-8 offset-xs-2 col-md-8 offset-md-2 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <ValidationProvider
              vid="page_name"
              rules="required"
              name="The Branch Name"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        label="Branch Name"
                        name="branch_name"
                        fou
                        v-model="formData.page_name">
              </fg-input>
            </ValidationProvider>

            <ValidationProvider
              vid="page_ident"
              rules="required"
              name="The Branch Ident"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        :disabled="editMode === true"
                        label="Branch Ident"
                        name="branch_ident"
                        fou
                        v-model="formData.page_ident">
              </fg-input>
            </ValidationProvider>
            <ValidationProvider
              vid="forum_id"
              rules="required"
              name="The Edition"
              v-slot="{ passed, failed,errors }">
              <fg-select
                name="forum_id"
                size="large"
                filterable
                clearable
                placeholder="Edition"
                :error="failed ? errors[0]: null"
                :input-classes="'select-default'"
                :label="'Edition'"
                :list="forumList"
                :listItemLabel="'name'"
                :listItemValue="'id'"
                v-model="formData.forum_id">
              </fg-select>
            </ValidationProvider>

          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/branches/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import FgSelect from "@/components/Inputs/formGroupSelect";


extend("required", {
  message: "{_field_} is required"
});

export default {
  components: {FgSelect},
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        page_name: "",
        page_ident: "",
        forum_id: '',
      },
      forumList: [],
    };
  },

  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.branchForm
    });

    this.axios.post("branches/builder").then((response) => {

      this.forumList = response.data.forumList;
      this.id = this.$route.params['id'];
      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Branch";
        this.getBranch();
      } else {
        this.editMode = false;
        this.formTitle = "Add Branch";
        this.loader.hide();
        if (response.data.currentForum){
          this.formData.forum_id = response.data.currentForum ? response.data.currentForum.id : null;
        }
      }

    }).catch((error) => {
      console.error(error);
    })
  },

  methods: {

    getBranch() {
      let data = {
          'id' : this.id,
      };
      this.axios.post("branches/get", data).then((response) => {
        this.formData.page_name = response.data.page_name;
        this.formData.page_ident = response.data.page_ident;
        this.formData.forum_id = response.data.forum_id;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Branch Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },


    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put("branches/update/" + this.id, this.formData);
        successMessage = "Branch Updated Successfully";
      } else {
        request = this.axios.post("branches/create", this.formData);
        successMessage = "Branch Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/branches/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    }

  }
}
</script>
